import React, { useState, useEffect } from "react";
import axios from "axios";
import { Table } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Response_Loading_processing from "../../../../src/layout/Others/Response_Loading_processing";
import deleteitem from "../../../components/Images/deleteitem.svg";
import edititem from "../../../components/Images/edititem.svg";
import AdminNavbar from "../../../components/CommonComponents/AdminNavbar";
import AdsCategories from "../Ads_Categories/AdsCategories";
import NoRecordTable from "../../../components/CommonComponents/NoRecordTable";
import { MdKeyboardDoubleArrowLeft, MdKeyboardDoubleArrowRight } from "react-icons/md";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { FaEllipsisVertical } from "react-icons/fa6";
import MessageBox from "../../../components/CommonComponents/MessageBox";
import AdminHeader from "../../../components/CommonComponents/AdminHeader";
import { toast } from "react-toastify";

function AdsList(props) {
  const [adsList, setAdsList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [itemToDelete, setItemToDelete] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [itemToDeleteadvertiser, setItemToDeleteadvertiser] = useState(null);
  const [successMessageadvertiser, setSuccessMessageadvertiser] = useState("");

  const navigate = useNavigate();
  const access_token = localStorage.getItem("access_token");

  const options = {
    totalSize: adsList?.length,
    page: currentPage,
    onPageChange: (page) => setCurrentPage(page),
    onSizePerPageChange: (sizePerPage, page) => {
      setPageSize(sizePerPage);
      setCurrentPage(page);
    },
    pageStartIndex: 1,
    paginationSize: 3,
    sizePerPage: pageSize,
    nextPageText: "Next",
    prePageText: "Prev",
    firstPageText: <MdKeyboardDoubleArrowLeft />,
    lastPageText: <MdKeyboardDoubleArrowRight />,
    showTotal: true,
    alwaysShowAllBtns: true,
    sizePerPageList: [
      { text: "5", value: 5 },
      { text: "10", value: 10 },
      { text: "All", value: Number(adsList?.length) },
    ],
  };

  const columns = [
    {
      dataField: "adsName",
      text: "Ad Name",
      formatter: (cell, row) => <div><p>{row?.ads_name}</p></div>,
    },
    {
      dataField: "adsType",
      text: "Ad Type",
      formatter: (cell, row) => <div><p>{row?.ads_upload_type}</p></div>,
    },
    {
      dataField: "adsFormat",
      text: "Ad Position",
      formatter: (cell, row) => <div><p>{row?.ads_position}</p></div>,
    },
    {
      dataField: "adsCategories",
      text: "Ad Category",
      formatter: (cell, row) => <div><p>{row?.ads_category_name}</p></div>,
    },
    {
      dataField: "adsStatus",
      text: "Status",
      formatter: (cell, row) => {
        const isActive = row?.status === "1" || row?.status === 1;
        const buttonClass = isActive ? "btn btn-success" : "btn btn-danger";
        return <div className="d-inline-flex text-start"><button className={buttonClass}>{isActive ? "Active" : "Inactive"}</button></div>;
      },
    },
    {
      dataField: "adsDate",
      text: "Created At",
      formatter: (cell, row) => {
        const date = new Date(row?.createdAt);
        const formattedDate = date.toLocaleString("en-US", {
          month: "long",
          day: "numeric",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        });
        return <div><p>{formattedDate}</p></div>;
      },
    },
    {
      dataField: "ACTIONS",
      text: "Actions",
      formatter: (cell, row) => (
        <div className="editdropdown rounded-2">
          <span className="editdropdown-button">
            <FaEllipsisVertical className="theme-text-color" />
          </span>
          <div className="editdropdown-menu theme-bg-color rounded-2">
            <Link to={`/ads-edit/${row?.id}`} className="theme-text-color theme-bg-color">
              <MessageBox text="Edit Ads" classname="ms-2 theme-text-color" />
            </Link>
            <Link to="#" className="delete">
              <span onClick={() => openDeleteModal(row?.id)}>
                <img src={deleteitem} alt="Delete" width={20} height={20} />
                <span className="ms-2">Delete Ads</span>
              </span>
            </Link>
          </div>
        </div>
      ),
    },
  ];

  const selectRow = {
    mode: "checkbox",
    onSelect: (row, isSelect) => {
      if (row) {
        if (isSelect) {
          setItemToDelete([...itemToDelete, row.id]);
        } else {
          setItemToDelete(itemToDelete?.filter((id) => id !== row?.id));
        }
      }
    },
    style: { width: "30px" },
    selected: itemToDelete,
    selectionHeaderRenderer: ({ mode, checked }) => (
      <div>
        <input type="checkbox" checked={checked} onChange={handleAll} />
      </div>
    ),
  };

  const handleAll = () => {
    setSelectAll(!selectAll);
    setItemToDelete(selectAll ? [] : adsList?.map((item) => item?.id));
  };

  const handleFilterChange = (data) => {
    setFilteredData(data);
  };

  const fetchDatanextpreadsList = () => {
    axios
      .get(
        `${process.env.REACT_APP_Baseurl_NODE_API}/admin/advertisement?page=${currentPage}`,
        { headers: { Authorization: `Bearer ${access_token}` } }
      )
      .then((res) => {
        const adsListsData = res?.data?.advertisements;
        setAdsList(adsListsData);
        setFilteredData(adsListsData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchDatanextpreadsList();
  }, [currentPage]);

  const openDeleteModal = (id) => {
    setItemToDeleteadvertiser(id);
    setShowDeleteModal(true);
  };

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
    setItemToDeleteadvertiser(null);
  };

  const handleDeleteAdvertiser = () => {
    if (itemToDeleteadvertiser) {
      advertisersdeleteOperation(itemToDeleteadvertiser);
    }
    closeDeleteModal();
  };

  const advertisersdeleteOperation = (id) => {
    axios
      .delete(
        `${process.env.REACT_APP_Baseurl_NODE_API}/admin/advertisement-delete/` + id,
        { headers: { Authorization: `Bearer ${access_token}` } }
      )
      .then((response) => {
        if (response.data.status === true) {
          toast.success("Advertisement deleted successfully!");
          fetchDatanextpreadsList(); // Refresh the data after deletion
        } else {
          toast.error("Failed to delete the advertisement!");
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error while deleting advertisement!");
      });
  };

  return (
    <>
      <AdminNavbar heading={"Advertisement List"} totalDataLength={adsList?.length} deleteCount={0} deleteMethod={null} />
      <div className="bootstrapTable p-3 rounded-2 theme-bg-color">
        <AdminHeader searchMethod={handleFilterChange} reduceData={adsList} originalData={adsList} fileName={"Advertisement"} />
        <BootstrapTable
          keyField="id"
          pagination={paginationFactory(options)}
          columns={columns}
          data={adsList}
          className="no-border-table theme-text-color"
          noDataIndication={<NoRecordTable result={adsList?.length} loader={false} />}
          selectRow={selectRow}
          selectAll={selectAll}
          hover
          headerClasses="videoWidth"
        />
      </div>

      {/* Delete Confirmation Modal */}
      {showDeleteModal && (
        <div className="modal fade show" tabIndex="-1" role="dialog" style={{ display: "block" }}>
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Confirm Deletion</h5>
                <button type="button" className="btn-close" onClick={closeDeleteModal} aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <p>Are you sure you want to delete this advertisement?</p>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" onClick={closeDeleteModal}>
                  Cancel
                </button>
                <button type="button" className="btn btn-danger" onClick={handleDeleteAdvertiser}>
                  Yes, Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default AdsList;
