import React, { useState, useEffect, useRef } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
} from "react-bootstrap-table2-paginator";
import {
  MdKeyboardDoubleArrowLeft,
  MdKeyboardDoubleArrowRight,
} from "react-icons/md";
import './UsersAnalytics.css'
import AdminHeader from "../../../components/CommonComponents/AdminHeader";
import axios from "axios";
import { getItemToken } from "../../../Utils/localStorageUtils";
import NoRecordTable from "../../../components/CommonComponents/NoRecordTable";
import { statusActiveFormatter } from "../../../components/CommonMethod/CommonBoostrapFormatter";
import MessageBox from "../../../components/CommonComponents/MessageBox";
import ApexAreaChart from "./Chart/ApexAreaChart";
import { IconAdmin, IconFilter, IconRegister, IconSubscriber } from "../../../Assets/MyIcons";

const UsersAnalytics = () => {

  const userAccessToken = getItemToken('access_token');

  const [filteredData, setFilteredData] = useState([]);
  const [apiData, setApiData] = useState([]);
  const [defaultData, setDefaultData] = useState({
    roles: [],
    filterTypes: [],
    totalPPVUser: [],
    totalAdmin: [],
    totalRegister: [],
    totalSubscriber: []
  })
  const [chartData, setChartData] = useState({
    categories: [],
    series: [
      {
        name: "USERS",
        data: []
      }
    ]
  });
  const [editUser, setEditUser] = useState({
    role: "",
    startDate: '',
    endDate: '',
    filterType: "",
  });

  const [withOutRefresh, setWithoutRefresh] = useState(false)
  const [loading, setLoading] = useState(false);

  const roleRef = useRef()
  const startDateRef = useRef()
  const endDateRef = useRef()
  const filterTypeRef = useRef()

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);

    const dateOptions = {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    };

    const timeOptions = {
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true
    };

    const formattedDate = date.toLocaleDateString('en-US', dateOptions);
    const formattedTime = date.toLocaleTimeString('en-US', timeOptions);

    return (
      <>
        <div>{formattedDate}</div>
        <div className='userDateTime'>{formattedTime}</div>
      </>
    );
  }

  const columns = [
    {
      dataField: "name",
      text: "USER",
      headerClasses: "userAnalyticsBox"
    },
    {
      dataField: "role",
      text: "ACC TYPE",
    },
    {
      dataField: "active",
      text: "STATUS",
      formatter: (cell, row) => statusActiveFormatter(cell, row),
    },
    {
      dataField: "created_at",
      text: "REGISTERED ON",
      formatter: (cell) => formatDate(cell),
    },
    {
      dataField: "country",
      text: "COUNTRY",
    },
    {
      dataField: "DOB",
      text: "DOB",
    },
    // {
    //   dataField: "source",
    //   text: "SOURCE",
    // },
  ];

  const options = {
    page: 1,
    sizePerPage: 10,
    nextPageText: "Next",
    prePageText: "Prev",
    firstPageText: <MdKeyboardDoubleArrowLeft />,
    lastPageText: <MdKeyboardDoubleArrowRight />,
    showTotal: true,
    alwaysShowAllBtns: true,
    sizePerPageList: [
      { text: "5", value: 5 },
      { text: "10", value: 10 },
      { text: "All", value: Number(filteredData?.length) },
    ],
  };

  const handleFilterChange = (data) => {
    setFilteredData(data);
  };

  const handleReload = () => {
    setWithoutRefresh(!withOutRefresh);
    setApiData([])
    setFilteredData([])
    setEditUser((prev) => ({
      ...prev,
      role: "",
      startDate: "",
      endDate: "",
      filterType: "",
    }))
  }

  const handleInput = (e) => {
    const { name, type, value, checked } = e.target;
    if (name === 'filterType') {
      setEditUser((prevEditUser) => ({
        ...prevEditUser,
        startDate: null,
        endDate: null
      }));
      if (!editUser?.role) {
        roleRef.current?.focus()
      }
    }
    if (name === "startDate" || name == "endDate") {
      setEditUser((prevEditUser) => ({
        ...prevEditUser,
        filterType: null,
      }));
      if (editUser?.startDate && !editUser?.endDate) {
        endDateRef.current.focus();
      } else if (editUser?.endDate && !editUser?.startDate) {
        startDateRef.current.focus();
      } else if (!editUser?.role) {
        roleRef.current?.focus();
      }
    }
    setEditUser((prevEditUser) => ({
      ...prevEditUser,
      [name]: value,
    }));
  };

  const fetchData = async () => {
    setLoading(true)
    try {
      const response = await axios.get(`${process.env.REACT_APP_Baseurl_NODE_API}/admin/users-analytics`, { headers: userAccessToken });
      const userData = response?.data?.Users;
      const FilterHelp = userData.map((item) => {
        const Role =
          item?.role == 1
            ? "Admin"
            : item?.role == 2
              ? "Register"
              : item?.role == 3
                ? "Subscriber"
                : item?.role == 4
                  ? "Channel Partner"
                  : item?.role == 5
                    ? "Content Partner"
                    : item?.role == 6
                      ? "Advertiser Partner"
                      : item?.role == 7
                        ? "Multi User"
                        : item?.role == 8
                          ? "PPV User"
                          : item?.role == 9
                            ? "Guest"
                            : "";
        const statusText = item?.active == 1 ? "ACTIVE" : item?.active == 0 ? "INACTIVE" : "PENDING";
        return { ...item, role: Role, active: statusText };
      });
      setApiData(FilterHelp);
      setFilteredData(FilterHelp);
      setChartData((prev) => ({
        ...prev,
        categories: response?.data?.monthly_revenue_months,
        series: [
          {
            name: "USERS",
            data: response?.data?.monthly_revenue_counts,
          }
        ],
      }))
      setDefaultData((prev) => ({
        ...prev,
        totalSubscriber: userData?.filter((item) => item?.role == 3),
        totalRegister: userData?.filter((item) => item?.role == 2),
        totalAdmin: userData?.filter((item) => item?.role == 1),
        totalPPVUser: userData?.filter((item) => item?.role == 404)
      }))
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const fetchRoleData = async () => {
    setLoading(true)
    try {
      const response = await axios.get(`${process.env.REACT_APP_Baseurl_NODE_API}/admin/account-types`, { headers: userAccessToken });
      const userRoles = response?.data?.UserRoles;
      setDefaultData((prev) => ({
        ...prev,
        roles: userRoles
      }))
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const fetchFilterTypeData = async () => {
    setLoading(true)
    try {
      const response = await axios.get(`${process.env.REACT_APP_Baseurl_NODE_API}/admin/filter-types`, { headers: userAccessToken });
      const types = response?.data?.FilterTypes;
      setDefaultData((prev) => ({
        ...prev,
        filterTypes: types
      }))
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const fetchFilterRoleData = async () => {

    setLoading(true)
    const requiredData = {
      role: editUser?.role
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_Baseurl_NODE_API}/admin/users-role-filter-analytics`, JSON.stringify(requiredData), { headers: userAccessToken });
      const userData = response?.data?.Users;
      const FilterHelp = userData.map((item) => {
        const Role =
          item?.role == 1
            ? "Admin"
            : item?.role == 2
              ? "Register"
              : item?.role == 3
                ? "Subscriber"
                : item?.role == 4
                  ? "Channel Partner"
                  : item?.role == 5
                    ? "Content Partner"
                    : item?.role == 6
                      ? "Advertiser Partner"
                      : item?.role == 7
                        ? "Multi User"
                        : item?.role == 8
                          ? "PPV User"
                          : item?.role == 9
                            ? "Guest"
                            : "";
        const statusText = item?.active == 1 ? "ACTIVE" : item?.active == 0 ? "INACTIVE" : "PENDING";
        return { ...item, role: Role, active: statusText };
      });
      setApiData(FilterHelp);
      setFilteredData(FilterHelp);
      setChartData((prev) => ({
        categories: response?.data?.monthly_revenue_months,
        series: [
          {
            name: editUser?.role == 1 ? "ADMIN" : editUser?.role == 2 ? "REGISTER" : editUser?.role == 3 && "SUBSCRIBER",
            data: response?.data?.monthly_revenue_counts,
          }
        ],
      }))
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const fetchFilterRoleAndDateData = async () => {
    setLoading(true)
    const requiredData = {
      role: editUser?.role,
      start_date: editUser?.startDate,
      end_date: editUser?.endDate
    }
    try {
      const response = await axios.post(`${process.env.REACT_APP_Baseurl_NODE_API}/admin/users-date-filter-analytics`, JSON.stringify(requiredData), { headers: userAccessToken });
      const userData = response?.data?.Users;
      const FilterHelp = userData.map((item) => {
        const Role =
          item?.role == 1
            ? "Admin"
            : item?.role == 2
              ? "Register"
              : item?.role == 3
                ? "Subscriber"
                : item?.role == 4
                  ? "Channel Partner"
                  : item?.role == 5
                    ? "Content Partner"
                    : item?.role == 6
                      ? "Advertiser Partner"
                      : item?.role == 7
                        ? "Multi User"
                        : item?.role == 8
                          ? "PPV User"
                          : item?.role == 9
                            ? "Guest"
                            : "";
        const statusText = item?.active == 1 ? "ACTIVE" : item?.active == 0 ? "INACTIVE" : "PENDING";
        return { ...item, role: Role, active: statusText };
      });
      setApiData(FilterHelp);
      setFilteredData(FilterHelp);
      setChartData((prev) => ({
        categories: response?.data?.monthly_revenue_months,
        series: [
          {
            name: editUser?.role == 1 ? "ADMIN" : editUser?.role == 2 ? "REGISTER" : editUser?.role == 3 && "SUBSCRIBER",
            data: response?.data?.monthly_revenue_counts,
          }
        ],
      }))
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const fetchFilterRoleAndDurationData = async () => {
    setLoading(true)
    const requiredData = {
      role: editUser?.role,
      filter_type: editUser?.filterType,
    }
    try {
      const response = await axios.post(`${process.env.REACT_APP_Baseurl_NODE_API}/admin/users-filter-analytics`, JSON.stringify(requiredData), { headers: userAccessToken });
      const userData = response?.data?.Users;
      const FilterHelp = userData.map((item) => {
        const Role =
          item?.role == 1
            ? "Admin"
            : item?.role == 2
              ? "Register"
              : item?.role == 3
                ? "Subscriber"
                : item?.role == 4
                  ? "Channel Partner"
                  : item?.role == 5
                    ? "Content Partner"
                    : item?.role == 6
                      ? "Advertiser Partner"
                      : item?.role == 7
                        ? "Multi User"
                        : item?.role == 8
                          ? "PPV User"
                          : item?.role == 9
                            ? "Guest"
                            : "";
        const statusText = item?.active == 1 ? "ACTIVE" : item?.active == 0 ? "INACTIVE" : "PENDING";
        return { ...item, role: Role, active: statusText };
      });
      setApiData(FilterHelp);
      setFilteredData(FilterHelp);
      setChartData((prev) => ({
        categories: response?.data?.monthly_revenue_months,
        series: [
          {
            name: editUser?.role == 1 ? "ADMIN" : editUser?.role == 2 ? "REGISTER" : editUser?.role == 3 && "SUBSCRIBER",
            data: response?.data?.monthly_revenue_counts,
          }
        ],
      }))
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const currentMonthGet = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth() + 1;
    const firstDay = new Date(year, month - 1, 1).toISOString().split('T')[0];
    const lastDay = new Date(year, month, 0).toISOString().split('T')[0];
    setEditUser((prev) => ({
      prev,
      startDate: firstDay,
      endDate: lastDay
    }))
  }
  useEffect(async () => {
    fetchData();
    fetchRoleData()
    fetchFilterTypeData()
    // currentMonthGet()
  }, [withOutRefresh]);

  useEffect(() => {
    if (editUser?.role && editUser?.startDate && editUser?.endDate) {
      fetchFilterRoleAndDateData()
    }
    else if (editUser?.role && editUser?.filterType) {
      fetchFilterRoleAndDurationData()
    }
    else if (editUser?.role) {
      fetchFilterRoleData()
    }
    else {
      fetchData();
    }
  }, [editUser])

  return (
    <>
      <div className={`d-flex align-items-center flex-wrap px-md-0 px-3 pb-3 `}>
        <div className="col-sm-6 col-12">
          <h4 className="admin-title mb-0 theme-text-color text-md-start "> <MessageBox text={'Users Analytics'} /></h4>
        </div>
      </div>
      <div className="d-flex align-items-stretch mb-3 flex-wrap justify-content-between">
        <div className="col-4 pe-2">
          <div className="theme-bg-color p-4 rounded-3 text-center d-flex align-items-center justify-content-between">
            <IconRegister styled={{ width: "40px", height: "40px", className: "theme-bgolor-secondary" }} />
            <div className="d-flex align-items-start justify-content-center flex-column">
              <p className="mb-0 countUsers">{defaultData?.totalRegister?.length}</p>
              <MessageBox text="Registered User" classname='mt-2 d-block' />
            </div>
          </div>
        </div>
        <div className="col-4 px-3">
          <div className="theme-bg-color p-4 rounded-3 text-center d-flex align-items-center justify-content-between">
            <IconSubscriber styled={{ width: "40px", height: "40px", className: "theme-bgolor-secondary" }} />
            <div className="d-flex align-items-start justify-content-center flex-column">
              <p className="mb-0 countUsers">{defaultData?.totalSubscriber?.length}</p>
              <MessageBox text="Subscribed User" classname='mt-2 d-block' />
            </div>
          </div>
        </div>
        <div className="col-4 ps-2">
          <div className="theme-bg-color p-4 rounded-3 text-center d-flex align-items-center justify-content-between">
            <IconAdmin styled={{ width: "40px", height: "40px", className: "theme-bgolor-secondary" }} />
            <div className="d-flex align-items-start justify-content-center flex-column">
              <p className="mb-0 countUsers">{defaultData?.totalAdmin?.length}</p>
              <MessageBox text="Admin User" classname='mt-2 d-block' />
            </div>
          </div>
        </div>
      </div>
      <div className="theme-bg-color p-3 mb-3 rounded-2">
        <div className="d-flex align-items-center p-3 flex-wrap">
          {/* <div className="col-3">
            <div className="d-flex align-items-center gap-3 mb-3 justify-content-between totalResult">
              <MessageBox text="Registered User" /><p>{defaultData?.totalRegister?.length}</p>
            </div>
            <div className="d-flex align-items-center gap-3 mb-3 justify-content-between totalResult">
              <MessageBox text="Subscribed User" /><p>{defaultData?.totalSubscriber?.length}</p>
            </div>
            <div className="d-flex align-items-center gap-3 mb-3 justify-content-between totalResult">
              <MessageBox text="Admin User" /><p>{defaultData?.totalAdmin?.length}</p>
            </div>
            <div className="d-flex align-items-center gap-3 mb-3 justify-content-between totalResult">
              <MessageBox text="PPV User" /><p>{defaultData?.totalPPVUser?.length}</p>
            </div>
          </div> */}
          <div className="col-12">
            <div className="d-flex align-items-center justify-content-between mb-3">
              <div className="position-relative">
                <IconFilter styled={{ width: "18px", height: "18px", className: "position-absolute filterIcon" }} />
                <select name="role" value={editUser?.role} onChange={handleInput} className="filterTypeCustom rounded-5    border border-1 theme-border-color  theme-text-color" ref={roleRef}>
                  <option value='' className="theme-bg-color theme-text-color" >Select Role</option>
                  {defaultData?.roles?.filter((item) => [1, 2, 3].includes(item?.id))?.map((item, index) => {
                    return <option key={item?.id} value={item?.id} className="theme-bg-color theme-text-color">{item?.role_name}</option>
                  })}
                </select>
              </div>
              <div className="d-flex align-items-center justify-content-between gap-3">
                <div className="position-relative">
                  <input type="date" name="startDate" value={editUser?.startDate || ''} max={editUser?.endDate || ''} onChange={handleInput} ref={startDateRef} className="filterTypeCustom calender rounded-5 theme-text-color border border-1 theme-border-color " />
                </div>
                <div className="position-relative">
                  <input type="date" name="endDate" value={editUser?.endDate || ''} min={editUser?.startDate || ''} onChange={handleInput} ref={endDateRef} className="filterTypeCustom calender rounded-5  theme-text-color border border-1 theme-border-color " />
                </div>
                <div className="position-relative">
                  <IconFilter styled={{ width: "18px", height: "18px", className: "position-absolute filterIcon" }} />
                  <p className="d-inline m-0 px-2">OR</p>
                  <select name="filterType" value={editUser?.filterType || ''} onChange={handleInput} className="filterTypeCustom rounded-5   border border-1 theme-border-color  theme-text-color" ref={filterTypeRef}>
                    <option value='' className="theme-bg-color theme-text-color" >Filter</option>
                    {defaultData?.filterTypes?.map((item, index) => {
                      return <option key={item?.filter_type} value={item?.filter_type} className="theme-bg-color theme-text-color">{item?.name}</option>
                    })}
                  </select>
                </div>
              </div>
            </div>
            <ApexAreaChart users={apiData ? apiData : []} chartData={chartData ? chartData : []} />
          </div>
        </div>
        {/* <div className="d-flex align-items-center pb-3">
          <div className="col-3">
            <select name="role" value={editUser?.role} onChange={handleInput} className="form-select  custom-placeholder   border border-1 theme-border-color  theme-text-color" ref={roleRef}>
              <option value='' className="theme-bg-color theme-text-color" >Select Role</option>
              {defaultData?.roles?.filter((item) => [1, 2, 3].includes(item?.id))?.map((item, index) => {
                return <option key={item?.id} value={item?.id} className="theme-bg-color theme-text-color">{item?.role_name}</option>
              })}
            </select>
          </div>
          <div className="col-9 px-4 d-flex align-items-center justify-content-end">
            <div className="col-3 pe-2">
              <input type="date" name="startDate" value={editUser?.startDate || ''} max={editUser?.endDate || ''} onChange={handleInput} ref={startDateRef} className="rs-input py-3 px-3 custom-placeholder theme-text-color border border-1 theme-border-color " />
            </div>
            <div className="col-3 pe-2">
              <input type="date" name="endDate" value={editUser?.endDate || ''} min={editUser?.startDate || ''} onChange={handleInput} ref={endDateRef} className="rs-input py-3 px-3 custom-placeholder theme-text-color border border-1 theme-border-color " />
            </div>
            <div className="col-3 d-flex align-items-center">
              <p className="d-inline m-0 px-2">OR</p>
              <select name="filterType" value={editUser?.filterType || ''} onChange={handleInput} className="form-select custom-placeholder   border border-1 theme-border-color  theme-text-color" ref={filterTypeRef}>
                <option value='' className="theme-bg-color theme-text-color" >Select Filter Type</option>
                {defaultData?.filterTypes?.map((item, index) => {
                  return <option key={item?.filter_type} value={item?.filter_type} className="theme-bg-color theme-text-color">{item?.name}</option>
                })}
              </select>
            </div>
          </div>
        </div> */}
      </div>
      <div className="theme-bg-color p-3 rounded-2">
        <div className=" bootstrapTable  multi-delete-width" >
          <AdminHeader searchMethod={handleFilterChange} reduceData={filteredData} originalData={apiData} fileName={'USER-LIST'} customUser reloadAction={handleReload} reload loading={loading} />
          <BootstrapTable keyField="id" pagination={paginationFactory(options)} columns={columns} data={loading ? [] : filteredData} className="no-border-table theme-text-color " noDataIndication={<NoRecordTable result={filteredData?.length} loader={loading} />} hover />
        </div>
      </div >
    </>
  );
};

export default UsersAnalytics;
