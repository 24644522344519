import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { Table } from "react-bootstrap";
import edititem from "../../../components/Images/edititem.svg";
import Response_Processing from "../../../../src/layout/Others/Response_Processing";
import "./EmailSettings.css";
import MessageLabel from "../../../components/CommonComponents/MessageLabel";
import Wrapper from "../../../components/CommonComponents/Wrapper";
import LeftWrapper from "../../../components/CommonComponents/LeftWrapper";
import FormContainer from "../../../components/CommonComponents/FormContainer";
import AdminSaveHeader from "../../../components/CommonComponents/AdminSaveHeader";
import MessageHeading from "../../../components/CommonComponents/MessageHeading";
import { RiLockPasswordLine } from "react-icons/ri";
import { TbEyeClosed } from "react-icons/tb";
import { VscEye } from "react-icons/vsc";
import { MdKeyboardDoubleArrowLeft, MdKeyboardDoubleArrowRight, MdOutlineMailOutline } from "react-icons/md";
import RightWrapper from "../../../components/CommonComponents/RightWrapper";
import MessageBox from "../../../components/CommonComponents/MessageBox";
import { toast } from "react-toastify";
import AdminCustomButton from "../../../components/CommonComponents/AdminCustomButton";
import AdminNavbar from "../../../components/CommonComponents/AdminNavbar";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import NoRecordTable from "../../../components/CommonComponents/NoRecordTable";
import { ActionFormatterTemplateComponent } from "../../../components/CommonMethod/CommonBoostrapFormatter";

function EmailSettings(props) {

  const access_token = localStorage.getItem("access_token");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  const adminEmailRef = useRef(null)
  const userEmailRef = useRef(null)
  const passWordRef = useRef(null)
  const emailHostRef = useRef(null)
  const emailPortRef = useRef(null)
  const userTestEmailRef = useRef(null)

  const [passwordType, setPasswordType] = useState("password");
  const [template, setTemplate] = useState([]);
  const [showOverlay, setShowOverlay] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10); //

  const [editUser, setEditUser] = useState({
    admin_email: "",
    user_email: "",
    email_password: "",
    host_email: "",
    email_port: "",
    secure: "",
  });

  const [editUserError, setEditUserError] = useState({})

  const [editUsertest, setEditUsertest] = useState({
    test_mail: "",
  });



  const getEmail = async () => {
    setShowOverlay(true)
    await axios.get(`${process.env.REACT_APP_Baseurl}/admin/Email-index`, { headers: headers })
      .then((response) => {
        setEditUser(response?.data?.email_settings);
        setEditUsertest(response?.email_settings);
        setShowOverlay(false)
      })
      .catch((error) => console.log(error));

  }

  const getTemplate = async () => {
    await axios.get(`${process.env.REACT_APP_Baseurl}/admin/Email-Template/index`, { headers: headers, })
      .then((response) => {
        var result = response?.data?.email_template;
        setTemplate(result);
      })
      .catch((error) => console.log(error));
  };


  const handleInput = (e) => {
    const { name, value } = e.target;

    if (name === "email_password") {
      // Regex to disallow spaces and the '#' character
      const filteredValue = value.replace(/[ #]/g, "");

      // Update the password field with the filtered value
      setEditUser({ ...editUser, [name]: filteredValue });
      setEditUsertest({ ...editUsertest, [name]: filteredValue });
    } else {
      // For other fields, no filtering
      setEditUser({ ...editUser, [name]: value });
      setEditUsertest({ ...editUsertest, [name]: value });
    }

    // Clear any field-specific errors
    setEditUserError({ ...editUserError, [name]: null });
  };

  const handleValidate = async (e) => {
    if (formValidation()) {
      handleUpdate()
    }
    else {
      console.log("Form validation failed ");
    }
  }

  const handleTestEmailValid = async () => {
    if (formTestEmailValidation()) {
      handleEmailSend()
    }
    else {
      console.log("Form validation failed ");
    }
  }

  const handleUpdate = async () => {

    const editUserUpdate = {
      "admin_email": editUser?.admin_email,
      "email_host": editUser?.host_email,
      "email_port": editUser?.email_port,
      "secure": editUser?.secure,
      "email_user": editUser?.user_email,
      "password": editUser?.email_password
    }
    setShowOverlay(true)
    await axios.post(`${process.env.REACT_APP_Baseurl}/admin/Email-update`, editUserUpdate, { headers: headers }).then((res) => {
      setShowOverlay(false)
      toast.success(res.data?.message)
      getEmail()
    }).catch((error) => {
      console.error("Error:", error);
    })
  }

  const handleEmailSend = async () => {
    setShowOverlay(true)
    const editUserUpdate = {
      "test_mail": editUser?.test_mail,
    }

    await axios.post(`${process.env.REACT_APP_Baseurl}/admin/Email-updates`, editUserUpdate, { headers: headers }).then((res) => {
      setShowOverlay(false)
      toast.success(res.data?.message)
    }).catch((error) => {
      const errMessage = error?.response?.data?.message || "Failed to send email"
      setShowOverlay(false)
      toast.error(errMessage)
      console.error("Error:", errMessage);

    })
  }


  const formValidation = () => {
    let formIsValid = true;
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!editUser?.admin_email) {
      setEditUserError((prevFormData) => ({
        ...prevFormData,
        admin_email: "Email cannot be empty.",
      }));
      adminEmailRef.current.focus();
      formIsValid = false;
    } else if (!regex.test(editUser?.admin_email)) {
      setEditUserError((prevFormData) => ({
        ...prevFormData,
        admin_email: "Email format is incorrect.",
      }));
      adminEmailRef.current.focus();
      formIsValid = false;
    }
    if (!editUser?.user_email) {
      setEditUserError((prevFormData) => ({
        ...prevFormData,
        user_email: "Email cannot be empty.",
      }));
      userEmailRef.current.focus();
      formIsValid = false;
    } else if (!regex.test(editUser?.user_email)) {
      setEditUserError((prevFormData) => ({
        ...prevFormData,
        user_email: "Email format is incorrect.",
      }));
      userEmailRef.current.focus();
      formIsValid = false;
    }
    if (!editUser?.email_password) {
      setEditUserError((prevFormData) => ({
        ...prevFormData,
        email_password: "Passsword cannot be empty",
      }));
      passWordRef.current.focus();
      formIsValid = false;
    }
    if (!editUser?.email_port) {
      setEditUserError((prevFormData) => ({
        ...prevFormData,
        email_port: "Email port cannot be empty",
      }));
      emailPortRef.current.focus();
      formIsValid = false;
    }
    if (!editUser?.host_email) {
      setEditUserError((prevFormData) => ({
        ...prevFormData,
        host_email: "Email host cannot be empty",
      }));
      emailHostRef.current.focus();
      formIsValid = false;
    }
    return formIsValid
  }

  const formTestEmailValidation = () => {
    let formIsValid = true;
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!editUser?.test_mail) {
      setEditUserError((prevFormData) => ({
        ...prevFormData,
        test_mail: "Email cannot be empty.",
      }));
      userTestEmailRef.current.focus();
      formIsValid = false;
    } else if (!regex.test(editUser?.test_mail)) {
      setEditUserError((prevFormData) => ({
        ...prevFormData,
        test_mail: "Email format is incorrect.",
      }));
      userTestEmailRef.current.focus();
      formIsValid = false;
    }
    return formIsValid
  }

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const options = {
    totalSize: template.length,
    page: currentPage,
    onPageChange: (page) => setCurrentPage(page),
    onSizePerPageChange: (sizePerPage, page) => {
      setPageSize(sizePerPage);
      setCurrentPage(page);
    },
    pageStartIndex: 1,
    paginationSize: 3,
    sizePerPage: pageSize,
    nextPageText: "Next",
    prePageText: "Prev",
    firstPageText: <MdKeyboardDoubleArrowLeft />,
    lastPageText: <MdKeyboardDoubleArrowRight />,
    showTotal: true,
    alwaysShowAllBtns: true,
    sizePerPageList: [{
      text: '5', value: 5
    }, {
      text: '10', value: 10
    }, {
      text: 'All', value: Number(template?.length)
    }]
  };


  const columns = [
    {
      dataField: "index",
      text: "S.No",
      sort: false,
      headerClasses: "serialNumber",
      formatter: (cell, row, rowIndex) => {
        return (currentPage - 1) * pageSize + rowIndex + 1;  // Calculate the serial number
      },
    },
    {
      dataField: "template_type",
      text: "TEMPLATE",
      sort: true,
    },
    {
      dataField: "heading",
      text: "SUBJECT",
      sort: true,
    },
    {
      dataField: "ACTION",
      text: "ACTION",
      headerClasses: "serialNumber",
      formatter: (cell, row) => <ActionFormatterTemplateComponent cell={cell} row={row} link={'/edit-template-email/'} />,
    },
  ];
  useEffect(async () => {
    getEmail();
    getTemplate()
  }, []);


  return (
    <div className="">
      {showOverlay && <Response_Processing></Response_Processing>}
      <AdminSaveHeader heading="Email Setting" saveText=" Update Settings" saveMethod={handleValidate} />
      <FormContainer>
        <LeftWrapper>
          <Wrapper>

            <MessageHeading text='Create Email' />

            <MessageLabel text="Admin Email" className="mandatorySimple" />
            <input type="email" name="admin_email" value={editUser?.admin_email} onChange={handleInput} placeholder="Enter admin email" ref={adminEmailRef} className="rs-input py-3 px-3 form-control-lg custom-placeholder theme-text-color border border-1 theme-border-color  mb-3" />
            {editUserError?.admin_email && (<MessageBox text={`${editUserError?.admin_email}`} classname='errorred mb-2 d-block' />)}

            <MessageLabel text="Email User" className="mandatorySimple" />
            <input type="email" name="user_email" value={editUser?.user_email} onChange={handleInput} placeholder="Enter user email" ref={userEmailRef} className="rs-input py-3 px-3 form-control-lg custom-placeholder theme-text-color  mb-3" />
            {editUserError?.user_email && (<MessageBox text={`${editUserError?.user_email}`} classname='errorred mb-2 d-block' />)}

            <MessageLabel text="Email Password" className="mandatorySimple" />
            <div className="position-relative">
              <input type={passwordType} name="email_password" value={editUser?.email_password} onChange={handleInput} placeholder="Enter Password" ref={passWordRef} className="rs-input py-3 px-5 form-control-lg custom-placeholder theme-text-color  mb-3" autoComplete="off" />
              <button onClick={togglePassword} className="position-absolute passWordIcon bg-transparent">
                {passwordType == "password" ? <TbEyeClosed className="eyeClosed " /> : <VscEye className="eyeOpen " />}
              </button>
              <RiLockPasswordLine className="lockIcon position-absolute" />
              {editUserError?.email_password && (<MessageBox text={`${editUserError?.email_password}`} classname='errorred mb-2 d-block' />)}
            </div>

          </Wrapper>
        </LeftWrapper>
        <RightWrapper>
          <Wrapper>

            <MessageHeading text='Email Host' />

            <MessageLabel text="Email Host" className="mandatorySimple" />
            <input type="email" name="host_email" value={editUser?.host_email} onChange={handleInput} placeholder="Enter host email" ref={emailHostRef} className="rs-input py-3 px-3 form-control-lg custom-placeholder theme-text-color border border-1 theme-border-color  mb-3" />
            {editUserError?.host_email && (<MessageBox text={`${editUserError?.host_email}`} classname='errorred mb-2 d-block' />)}

            <MessageLabel text="Email Port" className="mandatorySimple" />
            <input type="number" name="email_port" value={editUser?.email_port} onChange={handleInput} placeholder="Enter email port number" ref={emailPortRef} className="rs-input py-3 px-3 form-control-lg custom-placeholder theme-text-color  mb-3" />
            {editUserError?.email_port && (<MessageBox text={`${editUserError?.email_port}`} classname='errorred mb-2 d-block' />)}

            <MessageLabel text="Secure" />
            <select name="secure" value={editUser?.secure} onChange={handleInput} className="form-select mb-3 custom-placeholder  theme-bg-color-secondary theme-text-color border-0" >
              <option value='True' className="theme-bg-color theme-text-color">True</option>
              <option value='False' className="theme-bg-color theme-text-color">False</option>
            </select>

          </Wrapper>
        </RightWrapper>

      </FormContainer>

      <Wrapper>
        <MessageHeading text='Test Mail' />
        <MessageLabel text="Send email to User" className="" />
        <input type="email" name="test_mail" value={editUser?.test_mail} onChange={handleInput} placeholder="Enter test user email" ref={userTestEmailRef} className="rs-input py-3 px-3 form-control-lg custom-placeholder theme-text-color  mb-3" />
        {editUserError?.test_mail && (<MessageBox text={`${editUserError?.test_mail}`} classname='errorred mb-2 d-block' />)}
        <AdminCustomButton saveMethod={handleTestEmailValid} saveText={"Send"} />
      </Wrapper>
      <div className="bootstrapTable p-3 rounded-2 theme-bg-color">
        <AdminNavbar heading={'Email Templates'} totalDataLength={null} deleteCount={0} navigation={'/email-logs'} deleteMethod={null} navText={'Email Logs'} addIconHide />
        <BootstrapTable keyField="id" pagination={paginationFactory(options)} columns={columns} data={template} className="no-border-table theme-text-color " noDataIndication={<NoRecordTable result={template?.length} loader={false} />} hover headerClasses="videoWidth" />
      </div>
    </div>
  );
}

export default EmailSettings;
