import React, { useState, useEffect } from "react";
import axios from "axios";
import { Table } from "react-bootstrap";
import Response_Processing from "../../../../src/layout/Others/Response_Processing";

function ImageSettings(props) {
  const [compress_resolution_format, setCompress_resolution_format] = useState(
    []
  );
  const [editUser, setEditUser] = useState({
    compress_resolution_size: "",
    compress_resolution_format: "",
    enable_compress_image: "",
    audios: "",
    episode: "",
    season: "",
    series: "",
    live: "",
    videos: "",
  });
  const access_token = localStorage.getItem("access_token");

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  useEffect(async () => {
    await axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/compress_image`, {
        headers: headers,
      })
      .then((response) => {
        var result = response?.data?.Compress_image;
        setEditUser(result);
        var result = response?.data?.compress_resolution_format;
        setCompress_resolution_format(result);
      })
      .catch((error) => console.log(error));
  }, []);

  const handleInput = (e) => {
    const { name, value } = e.target;

    // Special handling for compress_resolution_size
    if (name === "compress_resolution_size") {
      // Convert the value to a number
      const numericValue = Number(value);

      // Clamp the value between 0 and 100
      let clampedValue = numericValue;
      if (numericValue < 0) {
        clampedValue = 0;
      } else if (numericValue > 100) {
        clampedValue = 100;
      }

      // Update the state with the clamped value
      setEditUser({ ...editUser, [name]: clampedValue });
    } else {
      // For other fields, update the state as usual
      setEditUser({ ...editUser, [name]: value });
    }
  };

  const handleInputenable = (e) => {
    if (e.target.checked === true) {
      setEditUser({ ...editUser, [e.target.name]: 1 });
    } else {
      setEditUser({ ...editUser, [e.target.name]: 0 });
    }
  };

  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  const handleUpdate = async (e) => {
    e.preventDefault();


    const enable_compress_image = Number.isNaN(parseInt(editUser?.enable_compress_image, 10)) ? 0 : parseInt(editUser?.enable_compress_image, 10);
    const videos = Number.isNaN(parseInt(editUser?.videos, 10)) ? 0 : parseInt(editUser?.videos, 10);


    const editInputvalue = {
      compress_resolution_size: editUser?.compress_resolution_size,
      compress_resolution_format: editUser?.compress_resolution_format,
      enable_compress_image: enable_compress_image,
      audios: editUser?.audios,
      episode: editUser?.episode,
      season: editUser?.season,
      series: editUser?.series,
      live: editUser?.live,
      videos: videos,
    };

    let firstApiStatus = false;
    let resultapi = null;
    let resjson = null;
    setProcessing(true);
    setShowOverlay(true);

    try {
      const res = await fetch(
        `${process.env.REACT_APP_Baseurl}/admin/compress_image_store`,
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify(editInputvalue),
        }
      );

      resjson = await res.json();

      if (resjson.status === true) {
        firstApiStatus = true;

        resultapi = resjson;
      }
    } catch (error) {
      console.error("Error:", error);
    }

    if (firstApiStatus) {
      props.setApiresponsealert(resultapi);
      props.setShowalert(true);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else {
      if (resjson.status === false) {
        var resultError = resjson;
        props.setApiresponsealert(resultError);
        props.setShowalert(true);
        setProcessing(false);
        setShowOverlay(false);
      }
    }
  };

  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="container-fluid">
            <div className="col-lg-12 row mt-3">
              <div className="col-7 col-sm-6 col-lg-6">
                <h3>Compress Image</h3>
              </div>
              <div className="col-5 col-sm-6 col-lg-6">
                <div className="text-end">
                  <button onClick={handleUpdate} className="btn btn-primary">
                    Save Settings
                  </button>
                </div>
              </div>
            </div>

            <div>
              {showOverlay && <Response_Processing></Response_Processing>}
            </div>

            <div className="row col-lg-12 mt-2">
              <div className=" col-sm-6 col-md-6 col-lg-6 ">
                <div className="card p-3">
                  <h5>Create Compress Image</h5>
                  <hr></hr>
                  <div className=" text-start">
                    <label className="m-0">
                      Compress Resolution Size ( % )
                    </label>
                    <div className="mt-2">
                      <input
                        type="number"
                        name="compress_resolution_size"
                        className="rs-input form-control-lg"
                        id="compress_resolution_size"
                        min="0"
                        max="100"
                        onChange={handleInput}
                        value={editUser?.compress_resolution_size}
                      />
                    </div>
                  </div>
                  <div className="mt-2 text-start">
                    <label className="m-0">Compress Resolution Format</label>

                    <div className="mt-2">
                      <select
                        className="form-select"
                        name="compress_resolution_format"
                        id="compress_resolution_format"
                        value={editUser?.compress_resolution_format}
                        onChange={handleInput}
                      >
                        {compress_resolution_format?.map((item) => (
                          <option value={item?.format}>{item?.name}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              {/* Second Part of Section  */}

              <div className=" col-sm-6 col-md-6 col-lg-6">
                <div className="card p-3">
                  <h5>Status Settings</h5>
                  <hr></hr>
                  <div className="row">
                    <div className="col-9 col-sm-9 col-lg-9">
                      <label className="m-0">Enable Compress for Images</label>
                    </div>
                    <div className="col-3 col-sm-3 col-lg-3 text-end">
                      <label className="switch">
                        <input
                          name="enable_compress_image"
                          id="enable_compress_image"
                          onChange={handleInputenable}
                          className="rs-input"
                          defaultChecked={
                            editUser?.enable_compress_image == 1 ? true : false
                          }
                          checked={
                            editUser?.enable_compress_image == 1 ? true : false
                          }
                          type="checkbox"
                        />
                        <span
                          className="slider round"
                          name="enable_compress_image"
                          id="enable_compress_image"
                          // onChange={handleInputenable}
                          value={
                            editUser?.enable_compress_image == 1 ? "1" : "0"
                          }
                        ></span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="section container-fluid">
            <div className="col-lg-12 row">
              <div className="col-12 col-sm-12 col-lg-12">
                <h3>Dimension Image validation</h3>
              </div>
            </div>

            <div className="iq-card mb-3">
              <div className="col-lg-12">
                <div className="">
                  <Table>
                    <thead>
                      <tr className="title">
                        <th>NAME</th>
                        <th>STATUS</th>
                      </tr>
                    </thead>
                    <tbody>
                      <td> Enable Videos </td>
                      <td>
                        <div className="mt-1">
                          <label className="switch">
                            <input
                              name="videos"
                              onChange={handleInputenable}
                              defaultChecked={
                                editUser?.videos === 1 ? true : false
                              }
                              checked={editUser?.videos === 1 ? true : false}
                              type="checkbox"
                            />
                            <span
                              className="slider round"
                              name="videos"
                              onChange={handleInputenable}
                              value={editUser?.videos === 1 ? "1" : "0"}
                            ></span>
                          </label>
                        </div>
                      </td>
                    </tbody>

                    <tbody>
                      <td> Enable Live Videos </td>
                      <td>
                        <div className="mt-1">
                          <label className="switch">
                            <input
                              name="live"
                              onChange={handleInputenable}
                              defaultChecked={
                                editUser?.live === 1 ? true : false
                              }
                              checked={editUser?.live === 1 ? true : false}
                              type="checkbox"
                            />
                            <span
                              className="slider round"
                              name="live"
                              onChange={handleInputenable}
                              value={editUser?.live === 1 ? "1" : "0"}
                            ></span>
                          </label>
                        </div>
                      </td>
                    </tbody>

                    <tbody>
                      <td> Enable Series</td>
                      <td>
                        <div className="mt-1">
                          <label className="switch">
                            <input
                              name="series"
                              onChange={handleInputenable}
                              defaultChecked={
                                editUser?.series === 1 ? true : false
                              }
                              checked={editUser?.series === 1 ? true : false}
                              type="checkbox"
                            />
                            <span
                              className="slider round"
                              name="series"
                              onChange={handleInputenable}
                              value={editUser?.series === 1 ? "1" : "0"}
                            ></span>
                          </label>
                        </div>
                      </td>
                    </tbody>

                    <tbody>
                      <td> Enable Season</td>
                      <td>
                        <div className="mt-1">
                          <label className="switch">
                            <input
                              name="season"
                              onChange={handleInputenable}
                              defaultChecked={
                                editUser?.season === 1 ? true : false
                              }
                              checked={editUser?.season === 1 ? true : false}
                              type="checkbox"
                            />
                            <span
                              className="slider round"
                              name="season"
                              onChange={handleInputenable}
                              value={editUser?.season === 1 ? "1" : "0"}
                            ></span>
                          </label>
                        </div>
                      </td>
                    </tbody>

                    <tbody>
                      <td> Enable Episode </td>
                      <td>
                        <div className="mt-1">
                          <label className="switch">
                            <input
                              name="episode"
                              onChange={handleInputenable}
                              defaultChecked={
                                editUser?.episode === 1 ? true : false
                              }
                              checked={editUser?.episode === 1 ? true : false}
                              type="checkbox"
                            />
                            <span
                              className="slider round"
                              name="episode"
                              onChange={handleInputenable}
                              value={editUser?.episode === 1 ? "1" : "0"}
                            ></span>
                          </label>
                        </div>
                      </td>
                    </tbody>

                    <tbody>
                      <td> Enable Audio </td>
                      <td>
                        <div className="mt-1">
                          <label className="switch">
                            <input
                              name="audios"
                              onChange={handleInputenable}
                              defaultChecked={
                                editUser?.audios === 1 ? true : false
                              }
                              checked={editUser?.audios === 1 ? true : false}
                              type="checkbox"
                            />
                            <span
                              className="slider round"
                              name="audios"
                              onChange={handleInputenable}
                              value={editUser?.audios === 1 ? "1" : "0"}
                            ></span>
                          </label>
                        </div>
                      </td>
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
            <div className="text-start mb-3">
              <button onClick={handleUpdate} className="btn btn-primary">
                Save Settings
              </button>
            </div>
          </section>
        </div>
      </div>

      {/* <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid ">
            <div id="content-page" className="p-2">
              <div className="iq-card">
                <div className="iq-card-header d-flex justify-content-between mb-3">
                  <div className="iq-header-title">
                    <h4 className="card-title">Compress Image</h4>
                  </div>
                </div>

                <div className="">
                  <form>
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-6">
                          <label for="">
                            {" "}
                            Compress Resolution Size <span> ( kb )</span>{" "}
                          </label>
                          <input
                            type="number"
                            name="compress_resolution_size"
                            // id="compress_resolution_size"
                            placeholder="Compress Resolution Size"
                            className="form-control"
                            required
                            onChange={handleInput}
                            value={editUser?.compress_resolution_size}
                          />
                          <br />
                        </div>

                        <div className="col-md-6">
                          <label for=""> Compress Resolution Format </label>

                          <select
                            className="form-control"
                            name="compress_resolution_format"
                            id="compress_resolution_format"
                            onChange={handleInput}
                          >
                            <option value="webp" onChange={handleInput}>
                              {" "}
                              Webp Format{" "}
                            </option>
                            <option value="jpg" onChange={handleInput}>
                              {" "}
                              JPG Format{" "}
                            </option>
                            <option value="jpeg" onChange={handleInput}>
                              {" "}
                              JPEG Format{" "}
                            </option>
                          </select>
                        </div>

                        <div className="col-md-9 row py-3">
                          <label className="col-md-5" for="">
                            Enable Compress for Images{" "}
                          </label>
                          <div className="mt-1 col-md-4">
                            <label className="switch">
                              <input
                                name="enable_compress_image"
                                id="enable_compress_image"
                                onChange={handleInput}
                                defaultChecked={
                                  editUser?.enable_compress_image === 1
                                    ? true
                                    : false
                                }
                                checked={
                                  editUser?.enable_compress_image === 1
                                    ? true
                                    : false
                                }
                                type="checkbox"
                              />
                              <span
                                className="slider round"
                                name="enable_compress_image"
                                id="enable_compress_image"
                                onChange={handleInput}
                                value={
                                  editUser?.enable_compress_image === 1
                                    ? "1"
                                    : "0"
                                }
                              ></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="iq-card-header d-flex justify-content-between mb-3">
                      <div className="iq-header-title">
                        <h4 className="card-title">
                          Dimension Image validation
                        </h4>
                      </div>
                    </div>

                    <div className="iq-card-body table-responsive p-0 text-center">
                      <div className="table-view">
                        <table
                          className="table table-striped table-bordered table movie_table iq-card"
                          id="Thumbnail"
                        >
                          <thead>
                            <tr className="r1">
                              <th>S.No</th>
                              <th>Name</th>
                              <th>Status</th>
                            </tr>
                          </thead>

                          <tbody>
                            <td>1</td>
                            <td> Videos </td>
                            <td>
                              <div className="mt-1">
                                <label className="switch">
                                  <input
                                    name="videos"
                                    onChange={handleInput}
                                    defaultChecked={
                                      editUser?.videos === 1 ? true : false
                                    }
                                    checked={
                                      editUser?.videos === 1 ? true : false
                                    }
                                    type="checkbox"
                                  />
                                  <span
                                    className="slider round"
                                    name="videos"
                                    onChange={handleInput}
                                    value={editUser?.videos === 1 ? "1" : "0"}
                                  ></span>
                                </label>
                              </div>
                            </td>
                          </tbody>

                          <tbody>
                            <td> 2</td>
                            <td> Live Videos </td>
                            <td>
                              <div className="mt-1">
                                <label className="switch">
                                  <input
                                    name="live"
                                    onChange={handleInput}
                                    defaultChecked={
                                      editUser?.live === 1 ? true : false
                                    }
                                    checked={
                                      editUser?.live === 1 ? true : false
                                    }
                                    type="checkbox"
                                  />
                                  <span
                                    className="slider round"
                                    name="live"
                                    onChange={handleInput}
                                    value={editUser?.live === 1 ? "1" : "0"}
                                  ></span>
                                </label>
                              </div>
                            </td>
                          </tbody>

                          <tbody>
                            <td> 3 </td>
                            <td> Series</td>
                            <td>
                              <div className="mt-1">
                                <label className="switch">
                                  <input
                                    name="series"
                                    onChange={handleInput}
                                    defaultChecked={
                                      editUser?.series === 1 ? true : false
                                    }
                                    checked={
                                      editUser?.series === 1 ? true : false
                                    }
                                    type="checkbox"
                                  />
                                  <span
                                    className="slider round"
                                    name="series"
                                    onChange={handleInput}
                                    value={editUser?.series === 1 ? "1" : "0"}
                                  ></span>
                                </label>
                              </div>
                            </td>
                          </tbody>

                          <tbody>
                            <td> 4 </td>
                            <td> Season</td>
                            <td>
                              <div className="mt-1">
                                <label className="switch">
                                  <input
                                    name="season"
                                    onChange={handleInput}
                                    defaultChecked={
                                      editUser?.season === 1 ? true : false
                                    }
                                    checked={
                                      editUser?.season === 1 ? true : false
                                    }
                                    type="checkbox"
                                  />
                                  <span
                                    className="slider round"
                                    name="season"
                                    onChange={handleInput}
                                    value={editUser?.season === 1 ? "1" : "0"}
                                  ></span>
                                </label>
                              </div>
                            </td>
                          </tbody>

                          <tbody>
                            <td>5 </td>
                            <td> Episode </td>
                            <td>
                              <div className="mt-1">
                                <label className="switch">
                                  <input
                                    name="episode"
                                    onChange={handleInput}
                                    defaultChecked={
                                      editUser?.episode === 1 ? true : false
                                    }
                                    checked={
                                      editUser?.episode === 1 ? true : false
                                    }
                                    type="checkbox"
                                  />
                                  <span
                                    className="slider round"
                                    name="episode"
                                    onChange={handleInput}
                                    value={editUser?.episode === 1 ? "1" : "0"}
                                  ></span>
                                </label>
                              </div>
                            </td>
                          </tbody>

                          <tbody>
                            <td> 6</td>
                            <td> Audio </td>
                            <td>
                              <div className="mt-1">
                                <label className="switch">
                                  <input
                                    name="audios"
                                    onChange={handleInput}
                                    defaultChecked={
                                      editUser?.audios === 1 ? true : false
                                    }
                                    checked={
                                      editUser?.audios === 1 ? true : false
                                    }
                                    type="checkbox"
                                  />
                                  <span
                                    className="slider round"
                                    name="audios"
                                    onChange={handleInput}
                                    value={editUser?.audios === 1 ? "1" : "0"}
                                  ></span>
                                </label>
                              </div>
                            </td>
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div className="col-md-12 form-group" align="right">
                      <button
                        type="submit"
                        className="btn btn-primary"
                        onClick={handleUpdate}
                      >
                        Update
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div> */}
    </>
  );
}

export default ImageSettings;
